<template>
  <component :is="infoTemplateComponent" :info="options.info[0]"></component>
</template>

<script>
import infoTemplatesRegistry from "./infoTemplates/infoTemplatesRegistry";

export default {
  components: {
    ...infoTemplatesRegistry.components
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    infoTemplateComponent() {
      if (!this.options.info[0]) {
        return false;
      }
      return infoTemplatesRegistry.GetComponent(this.options.info[0].blockType)
    }
  },
};
</script>

<style scoped>
</style>
